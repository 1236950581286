import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword, signInWithGoogle } from "../firebase";
import "./Register.css";
import { Form, Card, Image, Row, Button, InputGroup } from 'react-bootstrap';
import { FcGoogle,  } from "react-icons/fc";
import { SiFacebook, SiApple } from "react-icons/si";
import { MdAlternateEmail } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
    document.title = "linkdirim.com | Kayit Ol"
  }, [user, loading]);

  return (
    <>
    <div className="register">
      <Card className="shadow p-3 justify-content-md-center text-center m-1" style={{ width: '40rem', height: '40rem' }}>
        <Card.Header style={{backgroundColor: "white" }}>
          <Row className="justify-content-md-center m-3">
            <Image src='./nav.png' style={{width: "300px", height: "100px"}}/>
          </Row>
          <Row className="justify-content-md-center justify-content-between">
          <Button variant="" className="mr-2" style={{ borderRadius: '20px'}} onClick={signInWithGoogle}>
            <FcGoogle style={{width: '3rem', height: '2rem'}}/>
          </Button>{' '}
          <Button variant="" className="mr-2" style={{ borderRadius: '20px'}}>
            <SiFacebook style={{width: '3rem', height: '2rem', color: '#4285f4'}}/>
          </Button>{' '}
          <Button variant="" className="mr-2" style={{ borderRadius: '20px'}}>
            <SiApple style={{width: '3rem', height: '2rem'}}/>
          </Button>{' '}
          </Row>
        </Card.Header>
        <Card.Body className="justify-content-md-center m-1 ml-30 mr-30">
          <Card.Title></Card.Title>
          <Form className="justify-content-md-center m-1 ml-10 mr-10">
            <InputGroup>
              <InputGroup.Text><FaRegUser/></InputGroup.Text>
              <Form.Control id="" placeholder="Kullanici Adi" value={name} onChange={(e) => setName(e.target.value)}/>
            </InputGroup>
            <InputGroup>
              <InputGroup.Text><MdAlternateEmail/></InputGroup.Text>
              <Form.Control id="" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            </InputGroup>
            <InputGroup>
              <InputGroup.Text><RiLockPasswordFill/></InputGroup.Text>
              <Form.Control type="password" id="" placeholder="Sifre" value={password} onChange={(e) => setPassword(e.target.value)}/>
            </InputGroup>
            <Form.Group>
              <Button variant="secondary" style={{width: '8rem', height: '3rem'}} onClick={() => register()}>Kayit Ol</Button>
            </Form.Group>
            <br/>
            <Form.Group className="justify-content-between ">
              <p>Hesabin var mi? Simdi <Link to="/">giris yap!</Link> 🥳</p>
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer className="mt-3" style={{backgroundColor: "white", fontSize: '10px' }}>
          ❤️
        </Card.Footer>
      </Card>
    </div>
    </>
  );
}

export default Register;
