import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../pages/Dashboard.css";
import { auth, db, logout } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { Container, Image, Nav, Navbar, Tab, Tabs, Button, ButtonGroup, Dropdown, Form, Row, Col, Card, CardBody} from 'react-bootstrap';
import { BiLogOut, BiSolidBulb, BiLink, BiHappyHeartEyes, BiGridAlt, BiSolidUserPin } from "react-icons/bi";
import { PiGameControllerDuotone } from "react-icons/pi";
import { MdKeyboardAlt, MdFamilyRestroom, MdHomeWork } from "react-icons/md";
import { GiClothes } from "react-icons/gi";
import { IoHourglassOutline } from "react-icons/io5";
import { VscLink } from "react-icons/vsc";
import { GoCommentDiscussion } from "react-icons/go";
import { TbBrandGoogleFit } from "react-icons/tb";
import { SiAddthis } from "react-icons/si";

function Electronic() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
      setPhotoURL(data.photoURL);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    document.title = "linkdirim.com | Elektronik"

    fetchUserName();
  }, [user, loading]);

  return (
    <>
    <Navbar expand="lg" variant="light" bg="light" className="bg-body-tertiary justify-content-between shadow rounded-pill m-3" style={{ borderRadius:"10px"}}>
      <Container>
        <Navbar.Brand href="/dashboard">
          <Image src="./nav.png" style={{ height: "4rem", width: "10rem"}}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/electronic" active><MdKeyboardAlt/> Elektronik</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/game"><PiGameControllerDuotone/> Oyun</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/fashion"><GiClothes/> Moda</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/family"><MdFamilyRestroom/> Aile & Cocuk</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/home"><MdHomeWork/> Ev</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Form>
            <Row>
              <Col xs="auto">
                <Form.Control type="text" placeholder="Linkdirim Bul" className=""/>
              </Col>
              <Col></Col>
            </Row>
          </Form>
          <Navbar.Text className="mr-1">
            <Dropdown as={ButtonGroup}>
              <Button variant="secondary"><Image src={photoURL || "./no-pp.png"} style={{height: "30px", width: "30px", borderRadius: "30px"}}/></Button>
              <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" />
              <Dropdown.Menu>
                <Dropdown.Item href="/profile"><BiSolidUserPin/> Profil</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logout}><BiLogOut/> Cikis Yap</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Text>
          <Navbar.Text>
            <Button variant="" className="text-center" style={{ color: "orange", fontSize: '15px'}} href="/linkadd">
                <SiAddthis/> <span style={{ fontWeight: '', color: 'black'}}>Link Ekle</span>
            </Button>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Tabs defaultActiveKey="for_you" className="justify-content-md-center m-3">
      <Tab eventKey="for_you" title={<span><BiSolidBulb/> Senin Icin </span>} className="m-3 justify-content-md-center" variant="light">
        <Container>
          <Card bg="light" variant="light" className="shadow" style={{ borderRadius: '15px', border: "None" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col sm="2" className="justify-content-md-center mt-2">
                    <Container>
                      <Row>
                        <Image src="./no-image.png" style={{ maxHeight: "90%", maxWidth: "90%" }} rounded/>
                      </Row>
                    </Container>
                  </Col>
                  <Col sm="8" className="justify-content-md-center mt-2">
                    <Container>
                      <Row>
                        <span style={{ fontWeight: 'bold' }}>Linkdirim Baslik</span>
                      </Row>
                      <Row>
                        <span style={{ fontWeight: 'bold' }}>
                          <span style={{ color: 'orange' }}> %N/A Indirim </span> 
                          (ya da)
                          <span style={{ color: 'orange' }}> N/A₺ </span> 
                          -   
                          <span style={{ color: 'gray', textDecoration: "line-through"}}> N/A₺ </span>
                        </span>
                      </Row>
                      <br/>
                      <Row>
                        <span>Icerik detaylari burada yazmaktadir...</span>
                      </Row>
                      <br/>
                      <Row>
                        <Container>
                          <Image className="mr-1" src={photoURL || "./no-pp.png"} style={{height: "30px", width: "30px", borderRadius: "30px"}} rounded/>
                          <span className="mr-3">{name} </span>
                          <span className="mr-3"><VscLink/> 100 </span>
                          <span className="mr-3"><GoCommentDiscussion/> 20 </span>
                          <span className="mr-3"><TbBrandGoogleFit/> Marka Adi </span>
                          <span className="mr-3"><MdHomeWork/> Ev </span>
                        </Container>
                      </Row>
                    </Container>
                </Col>
                <Col sm="2" className="justify-content-md-center mt-2">
                  <Container>
                    <br/>
                    <Row>
                      <span style={{ color: "darkgray", fontWeight: 'bold' }}><IoHourglassOutline/> Son N/A saat!</span>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                      <Button variant="" style={{ borderRadius: '30px', fontWeight: "bold", float: "right", color: "black", backgroundColor: "orange" }}>Linke Git</Button>
                    </Row>
                  </Container>
                </Col>
              </Row>
              </Container>
            </CardBody>
          </Card>
        </Container>
      </Tab>
      <Tab eventKey="new_added" title={<span><BiLink/> Yeni Eklenenler </span>} className="m-3" variant="light">
        <Container>
          <Card bg="light" variant="light" className="shadow" style={{ borderRadius: '15px', border: "None" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col sm="2" className="justify-content-md-center mt-2">
                    <Container>
                      <Row>
                        <Image src="./no-image.png" style={{ maxHeight: "90%", maxWidth: "90%" }} rounded/>
                      </Row>
                    </Container>
                  </Col>
                  <Col sm="8" className="justify-content-md-center mt-2">
                    <Container>
                      <Row>
                        <span style={{ fontWeight: 'bold' }}>Linkdirim Baslik</span>
                      </Row>
                      <Row>
                        <span style={{ fontWeight: 'bold' }}>
                          <span style={{ color: 'orange' }}> %N/A Indirim </span> 
                          (ya da)
                          <span style={{ color: 'orange' }}> N/A₺ </span> 
                          -   
                          <span style={{ color: 'gray', textDecoration: "line-through"}}> N/A₺ </span>
                        </span>
                      </Row>
                      <br/>
                      <Row>
                        <span>Icerik detaylari burada yazmaktadir...</span>
                      </Row>
                      <br/>
                      <Row>
                        <Container>
                          <Image className="mr-1" src={photoURL || "./no-pp.png"} style={{height: "30px", width: "30px", borderRadius: "30px"}} rounded/>
                          <span className="mr-3">{name} </span>
                          <span className="mr-3"><VscLink/> 100 </span>
                          <span className="mr-3"><GoCommentDiscussion/> 20 </span>
                          <span className="mr-3"><TbBrandGoogleFit/> Marka Adi </span>
                          <span className="mr-3"><MdHomeWork/> Ev </span>
                        </Container>
                      </Row>
                    </Container>
                </Col>
                <Col sm="2" className="justify-content-md-center mt-2">
                  <Container>
                    <br/>
                    <Row>
                      <span style={{ color: "darkgray", fontWeight: 'bold' }}><IoHourglassOutline/> Son N/A saat!</span>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                      <Button variant="" style={{ borderRadius: '30px', fontWeight: "bold", float: "right", color: "black", backgroundColor: "orange" }}>Linke Git</Button>
                    </Row>
                  </Container>
                </Col>
              </Row>
              </Container>
            </CardBody>
          </Card>
        </Container>
      </Tab>
      <Tab eventKey="most_viewed" title={<span><BiHappyHeartEyes/> Cok Goruntulenenler </span>} className="m-3" variant="light">
        <Container>
          <Card bg="light" variant="light" className="shadow" style={{ borderRadius: '15px', border: "None" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col sm="2" className="justify-content-md-center mt-2">
                    <Container>
                      <Row>
                        <Image src="./no-image.png" style={{ maxHeight: "90%", maxWidth: "90%" }} rounded/>
                      </Row>
                    </Container>
                  </Col>
                  <Col sm="8" className="justify-content-md-center mt-2">
                    <Container>
                      <Row>
                        <span style={{ fontWeight: 'bold' }}>Linkdirim Baslik</span>
                      </Row>
                      <Row>
                        <span style={{ fontWeight: 'bold' }}>
                          <span style={{ color: 'orange' }}> %N/A Indirim </span> 
                          (ya da)
                          <span style={{ color: 'orange' }}> N/A₺ </span> 
                          -   
                          <span style={{ color: 'gray', textDecoration: "line-through"}}> N/A₺ </span>
                        </span>
                      </Row>
                      <br/>
                      <Row>
                        <span>Icerik detaylari burada yazmaktadir...</span>
                      </Row>
                      <br/>
                      <Row>
                        <Container>
                          <Image className="mr-1" src={photoURL || "./no-pp.png"} style={{height: "30px", width: "30px", borderRadius: "30px"}} rounded/>
                          <span className="mr-3">{name} </span>
                          <span className="mr-3"><VscLink/> 100 </span>
                          <span className="mr-3"><GoCommentDiscussion/> 20 </span>
                          <span className="mr-3"><TbBrandGoogleFit/> Marka Adi </span>
                          <span className="mr-3"><MdHomeWork/> Ev </span>
                        </Container>
                      </Row>
                    </Container>
                </Col>
                <Col sm="2" className="justify-content-md-center mt-2">
                  <Container>
                    <br/>
                    <Row>
                      <span style={{ color: "darkgray", fontWeight: 'bold' }}><IoHourglassOutline/> Son N/A saat!</span>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                      <Button variant="" style={{ borderRadius: '30px', fontWeight: "bold", float: "right", color: "black", backgroundColor: "orange" }}>Linke Git</Button>
                    </Row>
                  </Container>
                </Col>
              </Row>
              </Container>
            </CardBody>
          </Card>
        </Container>
      </Tab>
      <Tab eventKey="all_deals" title={<span><BiGridAlt/> Tum Firsatlar </span>} className="m-3" variant="light">
        <Container>
          <Card bg="light" variant="light" className="shadow" style={{ borderRadius: '15px', border: "None" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col sm="2" className="justify-content-md-center mt-2">
                    <Container>
                      <Row>
                        <Image src="./no-image.png" style={{ maxHeight: "90%", maxWidth: "90%" }} rounded/>
                      </Row>
                    </Container>
                  </Col>
                  <Col sm="8" className="justify-content-md-center mt-2">
                    <Container>
                      <Row>
                        <span style={{ fontWeight: 'bold' }}>Linkdirim Baslik</span>
                      </Row>
                      <Row>
                        <span style={{ fontWeight: 'bold' }}>
                          <span style={{ color: 'orange' }}> %N/A Indirim </span> 
                          (ya da)
                          <span style={{ color: 'orange' }}> N/A₺ </span> 
                          -   
                          <span style={{ color: 'gray', textDecoration: "line-through"}}> N/A₺ </span>
                        </span>
                      </Row>
                      <br/>
                      <Row>
                        <span>Icerik detaylari burada yazmaktadir...</span>
                      </Row>
                      <br/>
                      <Row>
                        <Container>
                          <Image className="mr-1" src={photoURL || "./no-pp.png"} style={{height: "30px", width: "30px", borderRadius: "30px"}} rounded/>
                          <span className="mr-3">{name} </span>
                          <span className="mr-3"><VscLink/> 100 </span>
                          <span className="mr-3"><GoCommentDiscussion/> 20 </span>
                          <span className="mr-3"><TbBrandGoogleFit/> Marka Adi </span>
                          <span className="mr-3"><MdHomeWork/> Ev </span>
                        </Container>
                      </Row>
                    </Container>
                </Col>
                <Col sm="2" className="justify-content-md-center mt-2">
                  <Container>
                    <br/>
                    <Row>
                      <span style={{ color: "darkgray", fontWeight: 'bold' }}><IoHourglassOutline/> Son N/A saat!</span>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                      <Button variant="" style={{ borderRadius: '30px', fontWeight: "bold", float: "right", color: "black", backgroundColor: "orange" }}>Linke Git</Button>
                    </Row>
                  </Container>
                </Col>
              </Row>
              </Container>
            </CardBody>
          </Card>
        </Container>
      </Tab>
    </Tabs>
    </>
  );
}

export default Electronic;
