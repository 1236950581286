import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Reset from "./pages/Reset";
import Dashboard from "./pages/Dashboard";
import Electronic from "./pages/Electronic";
import Game from "./pages/Game";
import Fashion from "./pages/Fashion";
import Family from "./pages/Family";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import LinkAdd from "./pages/LinkAdd";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/electronic" element={<Electronic />} />
          <Route exact path="/game" element={<Game />} />
          <Route exact path="/fashion" element={<Fashion />} />
          <Route exact path="/family" element={<Family />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/linkadd" element={<LinkAdd />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;