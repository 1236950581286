import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset, signInWithGoogle } from "../firebase";
import "./Reset.css";
import { Form, Card, Image, Row, Button, InputGroup } from 'react-bootstrap';
import { MdAlternateEmail } from "react-icons/md";
import { FcGoogle,  } from "react-icons/fc";
import { SiFacebook, SiApple } from "react-icons/si";

function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
    document.title = "linkdirim.com | Sifremi Yenile"
  }, [user, loading]);

  return (
    <>
    <div className="reset">
      <Card className="shadow p-3 justify-content-md-center text-center m-1" style={{ width: '40rem', height: '40rem' }}>
        <Card.Header style={{backgroundColor: "white" }}>
          <Row className="justify-content-md-center m-3">
            <Image src='./nav.png' style={{width: "300px", height: "100px"}}/>
          </Row>
          <Row className="justify-content-md-center justify-content-between">
          <Button variant="" className="mr-2" style={{ borderRadius: '20px'}} onClick={signInWithGoogle}>
            <FcGoogle style={{width: '3rem', height: '2rem'}}/>
          </Button>{' '}
          <Button variant="" className="mr-2" style={{ borderRadius: '20px'}}>
            <SiFacebook style={{width: '3rem', height: '2rem', color: '#4285f4'}}/>
          </Button>{' '}
          <Button variant="" className="mr-2" style={{ borderRadius: '20px'}}>
            <SiApple style={{width: '3rem', height: '2rem'}}/>
          </Button>{' '}
          </Row>
        </Card.Header>
        <Card.Body className="justify-content-md-center m-1 ml-30 mr-30">
          <Card.Title></Card.Title>
          <Form className="justify-content-md-center m-1 ml-10 mr-10">
            <InputGroup>
              <InputGroup.Text><MdAlternateEmail/></InputGroup.Text>
              <Form.Control id="" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            </InputGroup>
            <Form.Group>
                <Button variant="secondary" style={{width: '8rem', height: '3rem'}} onClick={() => sendPasswordReset(email)}>Sifremi Yenile</Button>
            </Form.Group>
            <br/>
            <Form.Group className="justify-content-between ">
              <p>Hesabin yok mu? Simdi <Link to="/register">kayit ol!</Link> 🥳</p>
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer className="mt-3" style={{backgroundColor: "white", fontSize: '10px' }}>
          ❤️
        </Card.Footer>
      </Card>
    </div>
    </>
  );
}

export default Reset;
