import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../pages/Dashboard.css";
import { auth, db, logout } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { Container, Image, Nav, Navbar, Button, ButtonGroup, Dropdown, Form, Row, Col} from 'react-bootstrap';
import { BiLogOut, BiSolidUserPin } from "react-icons/bi";
import { PiGameControllerDuotone } from "react-icons/pi";
import { MdKeyboardAlt, MdFamilyRestroom, MdHomeWork } from "react-icons/md";
import { GiClothes } from "react-icons/gi";
import { SiAddthis } from "react-icons/si";

function LinkAdd() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
      setPhotoURL(data.photoURL);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    document.title = "linkdirim.com | Link Ekle"

    fetchUserName();
  }, [user, loading]);

  return (
    <>
    <Navbar expand="lg" variant="light" bg="light" className="bg-body-tertiary justify-content-between shadow rounded-pill m-3" style={{ borderRadius:"10px"}}>
      <Container>
        <Navbar.Brand href="/dashboard">
          <Image src="./nav.png" style={{ height: "4rem", width: "10rem"}}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/electronic"><MdKeyboardAlt/> Elektronik</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/game"><PiGameControllerDuotone/> Oyun</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/fashion"><GiClothes/> Moda</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/family"><MdFamilyRestroom/> Aile & Cocuk</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/home"><MdHomeWork/> Ev</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Form>
            <Row>
              <Col xs="auto">
                <Form.Control type="text" placeholder="Linkdirim Bul" className=""/>
              </Col>
              <Col></Col>
            </Row>
          </Form>
          <Navbar.Text className="mr-1">
            <Dropdown as={ButtonGroup}>
              <Button variant="secondary"><Image src={photoURL || "./no-pp.png"} style={{height: "30px", width: "30px", borderRadius: "30px"}}/></Button>
              <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" />
              <Dropdown.Menu>
                <Dropdown.Item href="/profile"><BiSolidUserPin/> Profil</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logout}><BiLogOut/> Cikis Yap</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Text>
          <Navbar.Text>
            <Button variant="" className="text-center" style={{ color: "orange", fontSize: '15px'}} href="/linkadd">
                <SiAddthis/> <span style={{ fontWeight: '', color: 'black'}}>Link Ekle</span>
            </Button>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default LinkAdd;
