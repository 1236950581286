import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Container, Dropdown, Form, Image, Nav, Navbar, Row, Tab, Card } from 'react-bootstrap';
import { useAuthState } from "react-firebase-hooks/auth";
import { BiLogOut, BiSolidUserPin } from "react-icons/bi";
import { GiClothes } from "react-icons/gi";
import { MdFamilyRestroom, MdHomeWork, MdKeyboardAlt, MdSave } from "react-icons/md";
import { PiGameControllerDuotone } from "react-icons/pi";
import { SiAddthis } from "react-icons/si";
import { VscLink } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../firebase";
import "../pages/Dashboard.css";

function Profile() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [provider, setProvider] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      setName(data.name);
      setEmail(data.email);
      setPhotoURL(data.photoURL);
      setProvider(data.authProvider)
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    document.title = "linkdirim.com | Profil"

    fetchUserName();
  }, [user, loading]);

  return (
    <>
    <Navbar expand="lg" variant="light" bg="light" className="bg-body-tertiary justify-content-between shadow rounded-pill m-3" style={{ borderRadius:"10px"}}>
      <Container>
        <Navbar.Brand href="/dashboard">
          <Image src="./nav.png" style={{ height: "4rem", width: "10rem"}}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/electronic"><MdKeyboardAlt/> Elektronik</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/game"><PiGameControllerDuotone/> Oyun</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/fashion"><GiClothes/> Moda</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/family"><MdFamilyRestroom/> Aile & Cocuk</Nav.Link>
            <Nav.Link style={{ color: "black" }} className="nl_active" href="/home"><MdHomeWork/> Ev</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Form>
            <Row>
              <Col xs="auto">
                <Form.Control type="text" placeholder="Linkdirim Bul" className=""/>
              </Col>
              <Col></Col>
            </Row>
          </Form>
          <Navbar.Text className="mr-1">
            <Dropdown as={ButtonGroup}>
              <Button variant="secondary"><Image src={photoURL || "./no-pp.png"} style={{height: "30px", width: "30px", borderRadius: "30px"}}/></Button>
              <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" />
              <Dropdown.Menu>
                <Dropdown.Item href="/profile"><BiSolidUserPin/> Profil</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logout}><BiLogOut/> Cikis Yap</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Text>
          <Navbar.Text>
            <Button variant="" className="text-center" style={{ color: "orange", fontSize: '15px'}} href="/linkadd">
                <SiAddthis/> <span style={{ fontWeight: '', color: 'black'}}>Link Ekle</span>
            </Button>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container>
      <Col>
        <Container>
          <Tab.Container defaultActiveKey="profile-detail">
            <Row>
              <Col sm={5}>
                <Container>
                  <Card className="shadow" bg="light">
                    <Card.Body>
                      <Nav variant="light" className="flex-column" bg="light">
                        <Nav.Item>
                          <Nav.Link style={{ color: "black"}} eventKey="profile-detail"><BiSolidUserPin/> Profil Bilgileri</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link style={{ color: "black"}} eventKey="my-created-links"><VscLink/> Olusturdugum Linkler</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link style={{ color: "black"}} eventKey="saved-links"><MdSave/> Kayitli Linklerim</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Body>
                  </Card>
                </Container>
              </Col>
              <Col sm={7}>
                <Tab.Content>
                  <Tab.Pane eventKey="profile-detail">
                    <Container>
                      <Card className="shadow" bg="light" style={{ height: "30rem", maxHeight: "20%"}}>
                        <Card.Body className="justify-content-md-center">
                          <Row className="justify-content-md-center m-3">
                            <Container>
                                <Image src={photoURL || "./no-pp.png"} style={{ maxHeight: "90%", maxWidth: "90%" }} rounded/>
                              </Container>
                          </Row>
                          <Row className="m-3">
                            <Container>
                              <Row>
                                Kullanici Adi:
                              </Row>
                              <Row>
                                <Form.Control disabled placeholder={name}/>
                              </Row>
                              <br/>
                              <Row>
                                Email:
                              </Row>
                              <Row>
                                <Form.Control disabled placeholder={email}/>
                              </Row>
                              <br/>
                              <Row>
                                Hesap Tipi:
                              </Row>
                              <Row>
                                <Form.Control disabled placeholder={provider}/>
                              </Row>
                            </Container>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="my-created-links">
                    <Container>
                      <Card className="shadow" bg="light" style={{ height: "30rem", maxHeight: "20%"}}>
                        <Card.Body>
                          TBD
                        </Card.Body>
                      </Card>
                    </Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="saved-links">
                    <Container>
                      <Card className="shadow" bg="light" style={{ height: "30rem", maxHeight: "20%"}}>
                        <Card.Body>
                          TBD
                        </Card.Body>
                      </Card>
                    </Container>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Col>
      <Col>
      </Col>
    </Container>
    </>
  );
}

export default Profile;
